export const SINGLE_ACCESS_POINT_URL_SUFFIX = '/u/'
export const PATH_TO_MENU_ITEM_NAME_MAP = {
  dashboard: 'Start',
  items: 'Items Backoffice',
  'items/items': 'Items Backoffice',
  'items/events': 'Items Backoffice',
  'items/bookings': 'Items Backoffice',
  'items/item-lists': 'Items Backoffice',
  'fallback/items': 'FALLBACK.TITLE',
  forecast: 'FORECAST.TITLE',
  'daily-balance': 'DAILY_BALANCE.TITLE',
  'cash-delivery': 'CASH_DELIVERY.TITLE',
  'manage-customer': 'CUSTOMER_MANAGEMENT.TITLE',
  'delivery-list': 'DELIVERY_LIST.TITLE',
  'e-butiken': 'EBUTIKEN.TITLE',
  baseregistration: 'BASEREGISTER.TITLE',
  postportal: 'POSTPORTAL.TITLE',
  'delivery-agreements': 'Search Delivery Agreements',
  'user-admin': 'Retail User Administration',
  'fallback-superuser': 'Retail Emergency Fallback - Superuser',
  capabilities: 'Capabilities Backoffice',
  whitelists: 'Whitelisting Backoffice',
  trumpet: 'Retail Trumpet',
  'compensation-management': 'Compensation Management'
}

export const EXTERNAL_LINKS_IN_SIDEMENU = [
  'https://postnord.humany.net/foretagscenter/',
  'https://postnord.humany.net/postombudmobil/',
  'https://postnord.humany.net/utlamningsstallemobil/',
  'https://pablo.posten.se:7014/partnerweb'
]
